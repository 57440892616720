.link {
  color: white;
  text-decoration: none;
}

.link_in_tab {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
}

.link_in_tab:hover {
  color: white;
}

.img {
  /* background-color: white; */
  width: 40px;
  /* border-radius: 50%; */
}

.previous_nav {
  color: #009BE5;
}

.current_nav {
  color: #141D49;
}

.navigation_tree {
  margin: 20px 0 20px 25px;
  background-color: inherit;
  font-size: 13px;
  font-weight: 600;
  display: flex;
}

.logout {
  display: inline-flex;
  padding: 12px 10px;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  p {
    margin: 0;
    margin-right: 10px;
    font-weight: 700;
    font-size: 15px;
    text-align: right;
    width: 100%;
  }
}

.info {
  display: inline-flex;
  padding: 12px 10px;
  width: 100%;
  border-bottom: 1px solid #ccc;
  align-items: center;
  justify-content: space-between;

  p {
    margin: 0;
    margin-right: 10px;
    font-weight: 700;
    font-size: 15px;
    text-align: right;
    width: 100%;
  }
}