.popup_wrapper {
  position: fixed;
  z-index: 2000;
  width: 100vw;
  height: 100vh;
  background-color: #0008;
  top: 0;
  left: 0;
  display: flex;
}

.popup_content {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px 80px;
  margin: auto;
  position: relative;
  color: #141D49;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 700px;
}

.popup_close {
  height: fit-content;
  width: fit-content;
  line-height: 0;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.popup_title {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 35px;
}

.form {
  background-color: white;
  border-radius: 8px;
  padding: 5px;
  margin-top: 20px;
  border: solid 1px #0004;
}

.form_input {
  border-width: 0 !important;
  padding: 10px 7px;
  width: 400px;
}

.form_input_prefix_icon {
  font-size: 18px;
  margin-right: 10px;
}

.line {
  width: 90%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.15);
  margin: 5px 5%;
}

.form_button_wrapper {
  justify-content: center;
  display: flex;
  margin: 120px 0 30px 0;
}

.form_button {
  width: 150px;
  height: 45px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  color: white;
}

.form_button_cancel {
  margin-left: 10px;
  background-color: #CACACA;
  color: black;
}

.form_button_cancel:hover {
  background-color: #9A9A9A !important;
}

.form_button_primary {
  background-color: #141D49;
}

.form_button_primary:hover {
  background-color: #141D49cc !important;
}
