.card_content_label {
  display: flex;
  align-items: center;
  font-weight: 700;
  margin-top: 3px;
}

.list_card {
  min-width: 400px;
  max-width: 450px;
}

.adminCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  margin-bottom: 10px;
}

.adminInfo {
  flex-grow: 1;
  margin-left: 10px;
}

.adminName {
  font-weight: bold;
  margin-bottom: 5px;
}

.adminEmail {
  color: #888;
}

.adminActions {
  font-size: 20px;
  color: #000;
  text-decoration: none;
}

.delete_school_button {
  margin: 20px 0 0 20px;
  color: #141D49;
  font-weight: 700;
  cursor: pointer;
  width: max-content;
}